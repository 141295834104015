import React from "react";
import { graphql, Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";

// Layout
import { Page } from "../components/containers/page";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const Texts = ({ data }) => {
  const content = data.allPrismicText.nodes.map((text, index) => (
    <li
      key={`single_text_${index}_${text.id}`}
      className="single-text overview-grid list-item"
    >
      <div className="column left">
        <p>{text.data.format}</p>
      </div>

      <div className="column right">
        <Link to={text.url} state={{ section: text.id }}>
          <PrismicRichText field={text.data.title.richText} />
        </Link>
      </div>
    </li>
  ));

  return (
    <>
      <PageSeo seoTitle={`Texts`} seoImage={null} seoText={null} />

      <Page>
        <div className="page-grid">
          <div className="spacer" />

          <div className="content-container">
            <ol>{content}</ol>
          </div>

          <div className="spacer" />
        </div>

        <div className="page-bottom-border">
          <div className="border" />
        </div>
      </Page>
    </>
  );
};

export default withPrismicPreview(Texts);

export const query = graphql`
  query Texts {
    allPrismicText(sort: { fields: first_publication_date, order: DESC }) {
      nodes {
        url
        id
        data {
          title {
            richText
          }
          format
        }
      }
    }
  }
`;
